import { Card, CardContent } from "../../../components/ui/card";
import { navigationItems } from "../constants";
import { useAuth } from "../../../contexts/AuthContext";

interface DashboardProps {
  setActiveNav: (id: number) => void;
}

export const Dashboard: React.FC<DashboardProps> = ({ setActiveNav }) => {
  const { user } = useAuth();

  // Regular User Dashboard
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {navigationItems.slice(1).map((item) => (
        <Card 
          key={item.id}
          className="group cursor-pointer hover:shadow-lg transition-all duration-300 overflow-hidden"
          onClick={() => setActiveNav(item.id)}
        >
          <CardContent className="p-6">
            <div className={`w-12 h-12 rounded-lg ${item.color} mb-4 flex items-center justify-center group-hover:scale-110 transition-transform duration-300`}>
              <img
                className="h-6 w-6"
                alt={item.name}
                src={item.icon}
              />
            </div>
            <h3 className="text-lg font-semibold mb-2">{item.name}</h3>
            <p className="text-sm text-gray-600">
              {item.words.length} words available
            </p>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};