import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Checkbox } from "../../components/ui/checkbox";
import { useAuth } from "../../contexts/AuthContext";
import { AuthGamePreview } from "../../components/AuthGamePreview";
import { supabase } from "../../lib/supabase";

interface UserRole {
  roles: {
    name: string;
  };
}

export const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn, signInWithGoogle } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [debugInfo, setDebugInfo] = useState<string[]>([]);

  const addDebugInfo = (info: string) => {
    console.log('[SignIn Debug]', info);
    setDebugInfo(prev => [...prev, info]);
  };

  // Debug initial state
  useEffect(() => {
    const checkInitialState = async () => {
      addDebugInfo('Checking initial state...');
      
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        addDebugInfo(`Initial session check: ${session ? 'Session exists' : 'No session'}`);
        if (sessionError) {
          addDebugInfo(`Session check error: ${sessionError.message}`);
        }
        
        if (session?.user) {
          addDebugInfo(`Found existing user: ${session.user.id}`);
          addDebugInfo('User metadata:');
          
          // Check user role
          const { data: userRole, error: roleError } = await supabase
            .from('user_roles')
            .select('roles(name)')
            .eq('user_id', session.user.id)
            .single() as { data: UserRole | null, error: any };
          
          addDebugInfo(`Role check result: ${JSON.stringify(userRole)}`);
          if (roleError) {
            addDebugInfo(`Role check error: ${roleError.message}`);
          }
        }
      } catch (error: any) {
        addDebugInfo(`Initial state check error: ${error.message}`);
      }
    };

    checkInitialState();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    addDebugInfo('Starting sign in process...');
    addDebugInfo(`Attempting sign in for email: ${email}`);

    try {
      addDebugInfo('Calling signIn function...');
      await signIn(email, password);
      addDebugInfo('Sign in call completed successfully');

      // Check session immediately after sign in
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError) {
        addDebugInfo(`Post-signin session check error: ${sessionError.message}`);
        throw sessionError;
      }

      if (!session) {
        addDebugInfo('No session found after successful sign in');
        throw new Error('Session not established after sign in');
      }

      addDebugInfo(`Session established for user: ${session.user.id}`);
      addDebugInfo(`User metadata: ${JSON.stringify(session.user.user_metadata)}`);

      // Check if profile exists
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      addDebugInfo(`Profile check result: ${!!profile}`);
      if (profileError) {
        addDebugInfo(`Profile check error: ${profileError.message}`);
      }

      // Check user role
      const { data: userRole, error: roleError } = await supabase
        .from('user_roles')
        .select('roles(name)')
        .eq('user_id', session.user.id)
        .single() as { data: UserRole | null, error: any };

      addDebugInfo(`Role check result: ${JSON.stringify(userRole)}`);
      if (roleError) {
        addDebugInfo(`Role check error: ${roleError.message}`);
        if (roleError.code === 'PGRST116') {
          addDebugInfo('No role found, redirecting to role selection');
          navigate('/select-role');
          return;
        }
      }

      if (userRole?.roles?.name === 'teacher') {
        addDebugInfo('Teacher role found, redirecting to dashboard');
        navigate('/teacher-dashboard');
      } else {
        addDebugInfo('Non-teacher role found, redirecting to app');
        navigate('/app');
      }
    } catch (err: any) {
      addDebugInfo(`Sign in error: ${err.message}`);
      console.error('Detailed error:', err);
      setError(err.message || "Failed to sign in. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      addDebugInfo('Starting Google sign in process...');
      setLoading(true);
      addDebugInfo('Initiating Google OAuth flow...');
      await signInWithGoogle();
      addDebugInfo('Google sign in initiated successfully');
    } catch (err: any) {
      addDebugInfo(`Google sign in error: ${err.message}`);
      console.error('Detailed Google sign in error:', err);
      setError("Failed to sign in with Google. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Left side - Form */}
      <div className="flex w-full flex-col justify-between p-8 lg:w-1/2 lg:p-16">
        <div className="mx-auto w-full max-w-md">
          <img
            src="/deutschlylogo-1.png"
            alt="Deutschly Logo"
            className="mb-16 h-8"
          />
          <div>
            <h1 className="mb-2 text-4xl font-bold">Welcome back</h1>
            <p className="mb-8 text-gray-600">
              Welcome back! Please enter your details.
            </p>

            {error && (
              <div className="mb-6 rounded-lg bg-red-50 p-3 text-red-600">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="h-12"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="••••••••"
                  className="h-12"
                  required
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Checkbox id="remember" />
                  <label
                    htmlFor="remember"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Remember me
                  </label>
                </div>
                <Button variant="link" className="p-0">
                  Forgot password
                </Button>
              </div>

              <Button 
                type="submit" 
                className="h-12 w-full text-base"
                disabled={loading}
              >
                {loading ? "Signing in..." : "Sign in"}
              </Button>

              <Button
                type="button"
                variant="outline"
                className="flex h-12 w-full items-center justify-center gap-3 text-base"
                onClick={handleGoogleSignIn}
                disabled={loading}
              >
                <img src="/google.svg" alt="Google" className="h-6 w-6" />
                <span>Sign in with Google</span>
              </Button>
            </form>

            {debugInfo.length > 0 && (
              <div className="mt-6 rounded-lg bg-gray-50 p-4">
                <p className="text-sm font-medium mb-2">Debug Information:</p>
                <pre className="text-xs text-gray-600 whitespace-pre-wrap max-h-40 overflow-y-auto">
                  {debugInfo.join('\n')}
                </pre>
              </div>
            )}
          </div>
        </div>

        <p className="mt-8 text-center text-gray-600">
          Don't have an account?{" "}
          <Link to="/signup" className="font-semibold text-black">
            Sign up
          </Link>
        </p>
      </div>

      {/* Right side - Game Preview */}
      <div className="hidden lg:block lg:w-1/2">
        <AuthGamePreview />
      </div>
    </div>
  );
};