import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { useAuth } from "../../contexts/AuthContext";
import { AuthGamePreview } from "../../components/AuthGamePreview";

export const SignUp = () => {
  const navigate = useNavigate();
  const { signUp, signInWithGoogle } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    // Validate password length
    if (password.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    setLoading(true);
    console.log('Starting signup submission...');

    try {
      await signUp(email, password, name);
      console.log('Signup successful, navigating...');
      navigate("/select-role");
    } catch (err: any) {
      console.error('Signup error:', err);
      setError(err.message || "Failed to create account. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      console.log('SignUp: Initiating Google sign-up');
      setLoading(true);
      await signInWithGoogle();
      // Note: The page will be redirected by the signInWithGoogle function
    } catch (err) {
      console.error('SignUp: Failed to sign up with Google:', err);
      setError("Failed to sign up with Google. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Left side - Form */}
      <div className="w-full lg:w-1/2 flex flex-col p-8 lg:p-16 justify-between">
        <div className="max-w-md mx-auto w-full">
          <img
            src="/deutschlylogo-1.png"
            alt="Deutschly Logo"
            className="h-8 mb-16"
          />

          {error && (
            <div className="bg-red-50 text-red-600 p-3 rounded-lg mb-6">
              {error}
            </div>
          )}

          <div>
            <h1 className="text-4xl font-bold mb-2">Create your account</h1>
            <p className="text-gray-600 mb-8">
              Get started with your free account today
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="name">Full name</Label>
                <Input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                  className="h-12"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="h-12"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Create a password"
                  className="h-12"
                  required
                  minLength={6}
                />
                <p className="text-sm text-gray-500">
                  Password must be at least 6 characters long
                </p>
              </div>

              <Button 
                type="submit" 
                className="w-full h-12"
                disabled={loading}
              >
                {loading ? "Creating account..." : "Create account"}
              </Button>
            </form>

            <div className="relative my-8">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t"></span>
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-white px-2 text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <Button
              type="button"
              variant="outline"
              className="w-full h-12"
              onClick={handleGoogleSignIn}
              disabled={loading}
            >
              <img src="/google.svg" alt="Google" className="mr-2 h-5 w-5" />
              Sign up with Google
            </Button>
          </div>
        </div>

        <p className="text-center text-gray-600 mt-8">
          Already have an account?{" "}
          <Link to="/signin" className="text-black font-semibold">
            Sign in
          </Link>
        </p>
      </div>

      {/* Right side - Game Preview */}
      <div className="hidden lg:block lg:w-1/2">
        <AuthGamePreview />
      </div>
    </div>
  );
};