import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import { useAuth } from "../../contexts/AuthContext";
import { User } from "@supabase/supabase-js";
import { ProfileDialog } from "../../components/ProfileDialog";

interface NavigationItem {
  id: number;
  name: string;
  icon: string;
  active: boolean;
}

const defaultNavigationItems: NavigationItem[] = [
  { id: 1, name: "Dashboard", icon: "/icons-2.svg", active: false },
  { id: 2, name: "Nominativ", icon: "/icons.svg", active: true },
  { id: 3, name: "Akkusativ", icon: "/icons.svg", active: false },
  { id: 4, name: "Dativ", icon: "/icons.svg", active: false },
  { id: 5, name: "Genativ", icon: "/icons.svg", active: false },
  { id: 6, name: "Shuffle Mode", icon: "/icons.svg", active: false },
  { id: 7, name: "Homework", icon: "/icons-6.svg", active: false },
];

interface NavigationProps {
  user: User | null;
  activeNav: number;
  setActiveNav: (id: number) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  navigationItems?: NavigationItem[];
}

export const Navigation: React.FC<NavigationProps> = ({
  user,
  activeNav,
  setActiveNav,
  sidebarOpen,
  setSidebarOpen,
  navigationItems = defaultNavigationItems,
}) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);

  const handleSignOut = async () => {
    try {
      console.log('[Navigation] Starting sign out process');
      await signOut();
      console.log('[Navigation] Sign out completed successfully');
      // Navigation will be handled by AuthContext's onAuthStateChange listener
    } catch (error) {
      console.error('[Navigation] Error signing out:', error);
    }
  };

  return (
    <>
      <motion.aside
        initial={false}
        animate={{ width: sidebarOpen ? 300 : 0 }}
        transition={{ duration: 0.3 }}
        className="fixed left-0 top-0 h-full bg-[#0000001f] overflow-hidden z-50"
      >
        <div className="w-[300px] h-full flex flex-col p-6">
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-6">
              <img
                className="h-10 w-auto object-contain"
                alt="Deutschly logo"
                src="/deutschlylogo-1.png"
              />
            </div>

            <div className="mt-4 rounded-xl bg-gray-shadeslight-gray-1 p-3">
              <div className="flex items-center gap-3">
                <img className="h-5 w-5" alt="Search icon" src="/icons-5.svg" />
                <Input
                  className="h-8 border-none bg-transparent p-0 text-sm text-lightsubtext placeholder:text-lightsubtext focus-visible:ring-0"
                  placeholder="Search lessons..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            <nav className="mt-6">
              <ul className="flex flex-col gap-2">
                {navigationItems.map((item) => (
                  <li key={item.id}>
                    <Button
                      variant="ghost"
                      className={`w-full justify-start rounded-xl p-3 text-sm transition-all ${
                        activeNav === item.id
                          ? "bg-[#3d3d3dad] text-white font-semibold"
                          : "text-gray-shadesdark-gray hover:bg-white/10"
                      }`}
                      onClick={() => setActiveNav(item.id)}
                    >
                      <img
                        className="mr-3 h-5 w-5"
                        alt={item.name}
                        src={item.icon}
                      />
                      {item.name}
                    </Button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="flex items-center gap-3 rounded-xl bg-gray-50 p-3 mt-auto">
            <button 
              className="flex items-center w-full gap-3 text-left"
              onClick={() => setProfileDialogOpen(true)}
            >
              <span className="relative flex shrink-0 overflow-hidden h-12 w-12 flex-shrink-0 rounded-xl">
                <img 
                  className="aspect-square h-full w-full" 
                  alt="User avatar" 
                  src={user?.user_metadata?.avatar_url || "/default-avatar.png"}
                />
              </span>
              <div className="flex flex-col min-w-0 flex-1">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-bold text-gray-shadesdark-gray truncate pl-0">
                    {user?.user_metadata?.full_name || user?.user_metadata?.name || "Guest User"}
                  </span>
                  <button 
                    className="flex-shrink-0 rounded-lg p-1 hover:bg-white/10"
                    onClick={handleSignOut}
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none"
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1="21" y1="12" x2="9" y2="12" />
                    </svg>
                  </button>
                </div>
                <span className="text-xs text-gray-shadesdark-shade truncate">
                  {user?.email || "Not signed in"}
                </span>
              </div>
            </button>
          </div>
        </div>
      </motion.aside>

      <ProfileDialog 
        open={profileDialogOpen}
        onOpenChange={setProfileDialogOpen}
      />
    </>
  );
};