import React from 'react';
import { Card, CardContent } from './ui/card';
import { motion } from 'framer-motion';

export type UserRole = 'self_learner' | 'teacher' | 'student';

interface RoleOption {
  id: UserRole;
  title: string;
  icon: string;
  description: string;
}

const roleOptions: RoleOption[] = [
  {
    id: 'self_learner',
    title: "I'm a Self Learner",
    icon: '👤',
    description: 'Learn German at your own pace'
  },
  {
    id: 'teacher',
    title: "I'm a Teacher",
    icon: '👨‍🏫',
    description: 'Create and manage classes'
  },
  {
    id: 'student',
    title: "I'm a Student",
    icon: '👨‍🎓',
    description: 'Join a class and learn together'
  }
];

interface RoleSelectionProps {
  onSelect: (role: UserRole) => void;
}

export const RoleSelection: React.FC<RoleSelectionProps> = ({ onSelect }) => {
  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-2">What best describes you?</h2>
        <p className="text-gray-600">Choose your role to get started</p>
      </div>

      <div className="grid gap-4">
        {roleOptions.map((option) => (
          <motion.div
            key={option.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Card
              className="cursor-pointer hover:shadow-md transition-shadow"
              onClick={() => onSelect(option.id)}
            >
              <CardContent className="flex items-center p-6">
                <span className="text-4xl mr-4">{option.icon}</span>
                <div>
                  <h3 className="font-semibold">{option.title}</h3>
                  <p className="text-sm text-gray-600">{option.description}</p>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};