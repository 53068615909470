/// <reference types="vite/client" />

import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Missing Supabase environment variables. Please check your .env file.');
}

export const supabase = createClient(
  supabaseUrl || 'https://placeholder.supabase.co',
  supabaseAnonKey || 'placeholder-key',
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: true,
      flowType: 'implicit',
      storage: window.localStorage,
      storageKey: 'deutschly-auth',
      debug: true
    }
  }
);

// For debugging auth issues
supabase.auth.onAuthStateChange((event, session) => {
  console.log('[Supabase Debug] Auth event:', event);
  console.log('[Supabase Debug] Session exists:', !!session);
  if (session?.user) {
    console.log('[Supabase Debug] User ID:', session.user.id);
    console.log('[Supabase Debug] User email:', session.user.email);
    console.log('[Supabase Debug] Auth provider:', session.user.app_metadata.provider);
    console.log('[Supabase Debug] User metadata:', session.user.user_metadata);
    console.log('[Supabase Debug] App metadata:', session.user.app_metadata);
    console.log('[Supabase Debug] Access token:', session.access_token ? 'Present' : 'Missing');
    console.log('[Supabase Debug] Refresh token:', session.refresh_token ? 'Present' : 'Missing');
  }
});

// Profile types
export interface Profile {
  id: string;
  full_name: string | null;
  avatar_url: string | null;
  created_at: string;
  updated_at: string;
}

// Helper functions for profile management
export const getProfile = async (userId: string) => {
  if (!supabaseUrl || !supabaseAnonKey) {
    throw new Error('Supabase is not properly configured');
  }

  const { data, error } = await supabase
    .from('profiles')
    .select('*')
    .eq('id', userId)
    .single();

  if (error) throw error;
  return data as Profile;
};

export const updateProfile = async (userId: string, updates: Partial<Profile>) => {
  if (!supabaseUrl || !supabaseAnonKey) {
    throw new Error('Supabase is not properly configured');
  }

  const { error } = await supabase
    .from('profiles')
    .update({
      ...updates,
      updated_at: new Date().toISOString(),
    })
    .eq('id', userId);

  if (error) throw error;
};