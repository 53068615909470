import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Input } from './ui/input';

// Hardcoded words from the database
const previewWords = [
  { english: "year", german: "Jahr", article: "das" },
  { english: "time", german: "Zeit", article: "die" },
  { english: "example", german: "Beispiel", article: "das" },
  { english: "woman", german: "Frau", article: "die" },
  { english: "human being", german: "Mensch", article: "der" }
];

const variants = {
  enter: (direction: number) => ({
    y: direction > 0 ? 100 : -100,
    opacity: 0,
    scale: 0.8
  }),
  center: {
    y: 0,
    opacity: 1,
    scale: 1,
    zIndex: 1
  },
  exit: (direction: number) => ({
    y: direction < 0 ? 100 : -100,
    opacity: 0,
    scale: 0.8,
    zIndex: 0
  })
};

const KeyDisplay = ({ 
  keyName, 
  isPressed,
  onClick 
}: { 
  keyName: string; 
  isPressed: boolean;
  onClick?: () => void;
}) => (
  <motion.div
    initial={{ scale: 1 }}
    animate={{ scale: isPressed ? 0.9 : 1 }}
    className={`w-12 h-12 rounded-lg border-2 flex items-center justify-center cursor-pointer ${
      isPressed ? 'bg-white/10 border-white/50' : 'bg-transparent border-white/30'
    } transition-colors`}
    onClick={onClick}
    role="button"
    tabIndex={0}
    aria-label={`Select article ${
      keyName === 'ArrowLeft' ? 'der' :
      keyName === 'ArrowUp' ? 'die' :
      keyName === 'ArrowRight' ? 'das' :
      keyName === 'ArrowDown' ? 'skip' : ''
    }`}
  >
    <span className="text-sm font-medium text-white">
      {keyName === 'ArrowLeft' ? 'der' :
       keyName === 'ArrowUp' ? 'die' :
       keyName === 'ArrowRight' ? 'das' :
       keyName === 'ArrowDown' ? 'skip' : ''}
    </span>
  </motion.div>
);

export const LandingPreview = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userArticle, setUserArticle] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [direction, setDirection] = useState(0);
  const [pressedKey, setPressedKey] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentIndex]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(e.key)) {
        e.preventDefault();
        setPressedKey(e.key);
        
        if (e.key === 'ArrowDown') {
          handleNext();
          return;
        }
        
        const articleMap = {
          'ArrowLeft': 'der',
          'ArrowUp': 'die',
          'ArrowRight': 'das'
        };
        
        const article = articleMap[e.key as keyof typeof articleMap];
        if (article) {
          setUserArticle(article);
          handleArticleSubmit(article);
        }
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(e.key)) {
        setPressedKey(null);
      }
    };

    if (!isLoading) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [currentIndex, isLoading]);

  const handleNext = () => {
    if (isLoading) return;
    setDirection(1);
    setCurrentIndex((prev) => (prev + 1) % previewWords.length);
    setUserArticle("");
    setShowResult(false);
  };

  const handleArticleSubmit = (article?: string) => {
    if (isLoading) return;
    
    const currentArticle = article || userArticle;
    if (!currentArticle) return;
    
    const currentWord = previewWords[currentIndex];
    const isCorrect = currentArticle.toLowerCase() === currentWord.article.toLowerCase();
    
    setShowResult(true);
    if (isCorrect) {
      setTimeout(() => {
        handleNext();
      }, 500);
    }
  };

  const handleArticleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setUserArticle(value);
    
    // Auto-check if the input matches any of the valid articles
    if (['der', 'die', 'das'].includes(value)) {
      handleArticleSubmit(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleArticleSubmit();
    }
  };

  const handleArticleKeyPress = (keyName: string) => {
    // Simulate key press effect
    setPressedKey(keyName);
    setTimeout(() => setPressedKey(null), 150);
    
    if (keyName === 'ArrowDown') {
      handleNext();
      return;
    }
    
    const articleMap = {
      'ArrowLeft': 'der',
      'ArrowUp': 'die',
      'ArrowRight': 'das'
    };
    
    const article = articleMap[keyName as keyof typeof articleMap];
    if (article) {
      setUserArticle(article);
      handleArticleSubmit(article);
    }
  };

  const currentWord = previewWords[currentIndex];

  return (
    <div className="h-full bg-[#111111] flex flex-col items-center justify-between py-8">
      <div className="text-center space-y-2 px-4">
        <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
          Master German Grammar
        </h2>
        <p className="text-xl text-gray-400">Join others in mastering German grammar through interactive practice</p>
      </div>

      <div className="flex-1 flex items-center justify-center min-h-[280px] w-full px-4">
        <AnimatePresence mode="wait" custom={direction}>
          <motion.div
            key={currentIndex}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              y: { type: "spring", stiffness: 500, damping: 30 },
              opacity: { duration: 0.2 },
              scale: { duration: 0.2 }
            }}
            className="w-full max-w-2xl"
          >
            <div className="text-center space-y-8">
              <p className="text-4xl sm:text-5xl lg:text-6xl italic text-gray-500 font-['Times_New_Roman']">
                {currentWord.english}
              </p>
              <div className="word-container justify-center">
                <div className="relative">
                  <Input
                    ref={inputRef}
                    type="text"
                    value={userArticle}
                    onChange={handleArticleChange}
                    onKeyDown={handleKeyDown}
                    className={`article-input w-40 text-center bg-transparent text-white border-white font-['Times_New_Roman'] ${
                      showResult
                        ? userArticle.toLowerCase() === currentWord.article.toLowerCase()
                          ? "correct border-green-500 text-green-500"
                          : "incorrect border-red-500 text-red-500"
                        : "border-white"
                    }`}
                    placeholder=""
                  />
                </div>
                <span className="text-[48px] sm:text-[64px] lg:text-[90px] font-bold font-['Times_New_Roman'] text-white">
                  {currentWord.german}
                </span>
              </div>

              {showResult && (
                <motion.p
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={`text-xl font-semibold ${
                    userArticle.toLowerCase() === currentWord.article.toLowerCase()
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {userArticle.toLowerCase() === currentWord.article.toLowerCase()
                    ? "Correct! 🎉"
                    : "Wrong! Try again"}
                </motion.p>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      <div className="text-center space-y-6 mt-auto pb-10">
        <div className="flex justify-center">
          <div className="grid grid-cols-3 gap-2 w-fit">
            <div className="col-start-2">
              <KeyDisplay 
                keyName="ArrowUp" 
                isPressed={pressedKey === 'ArrowUp'} 
                onClick={() => handleArticleKeyPress('ArrowUp')}
              />
            </div>
            <div className="col-start-1 col-end-4 grid grid-cols-3 gap-2">
              <KeyDisplay 
                keyName="ArrowLeft" 
                isPressed={pressedKey === 'ArrowLeft'} 
                onClick={() => handleArticleKeyPress('ArrowLeft')}
              />
              <KeyDisplay 
                keyName="ArrowDown" 
                isPressed={pressedKey === 'ArrowDown'} 
                onClick={() => handleArticleKeyPress('ArrowDown')}
              />
              <KeyDisplay 
                keyName="ArrowRight" 
                isPressed={pressedKey === 'ArrowRight'} 
                onClick={() => handleArticleKeyPress('ArrowRight')}
              />
            </div>
          </div>
        </div>
        
        <p className="text-lg text-gray-400">
          Use arrow keys or type to select articles
        </p>
      </div>
    </div>
  );
};