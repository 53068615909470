import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';

interface ProfileDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const ProfileDialog: React.FC<ProfileDialogProps> = ({ open, onOpenChange }) => {
  const navigate = useNavigate();
  const { user, profile, updateUserProfile } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [name, setName] = useState(profile?.full_name || user?.user_metadata?.full_name || '');
  const [selectedRole, setSelectedRole] = useState('');
  const [teacherCode, setTeacherCode] = useState('');
  const [showTeacherCode, setShowTeacherCode] = useState(false);
  const [isTeacherCodeValid, setIsTeacherCodeValid] = useState(false);

  const handleRoleChange = (role: string) => {
    if (role === 'teacher') {
      setShowTeacherCode(true);
      setSelectedRole(role);
      return;
    }

    // For non-teacher roles, just set the role and wait for save
    setSelectedRole(role);
  };

  const handleTeacherCodeSubmit = async () => {
    try {
      setLoading(true);
      setError('');
      
      console.log('Verifying teacher code...');

      if (teacherCode.toUpperCase() !== 'TEACHER') {
        setError('Invalid teacher code');
        setIsTeacherCodeValid(false);
        return;
      }

      // Mark code as valid
      setIsTeacherCodeValid(true);
      setError(''); // Clear any existing errors
    } catch (err) {
      console.error('Error verifying teacher code:', err);
      setError('Failed to verify code. Please try again.');
      setIsTeacherCodeValid(false);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      setLoading(true);
      setError('');
      console.log('Starting profile update...', { name, selectedRole, isTeacherCodeValid });

      if (!user?.id) {
        console.error('No user ID found');
        throw new Error('No user ID found');
      }

      // First update the profile name if it's not empty
      if (name.trim()) {
        console.log('Updating profile name...');
        try {
          // Ensure we have a valid session before proceeding
          const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
          if (sessionError) {
            console.error('Session error:', sessionError);
            throw new Error('Failed to verify session');
          }
          
          if (!sessionData.session) {
            console.error('No valid session found');
            throw new Error('No valid session found');
          }
          
          console.log('Session verified, updating profile...');
          await updateUserProfile({
            full_name: name.trim(),
          });
          console.log('Profile name updated successfully');
        } catch (profileError: any) {
          console.error('Error updating profile:', profileError);
          throw new Error(profileError?.message || 'Failed to update profile name');
        }
      } else {
        console.log('Skipping profile name update - name is empty');
      }

      // Handle role update if a role is selected
      if (selectedRole) {
        try {
          console.log('Getting role ID for:', selectedRole);
          
          // Debug: Check roles table
          const { data: allRoles, error: rolesError } = await supabase
            .from('roles')
            .select('*');
          console.log('All roles in database:', allRoles, 'Error:', rolesError);

          // Get role ID
          const { data: roleData, error: roleError } = await supabase
            .from('roles')
            .select('id, name')
            .eq('name', selectedRole)
            .single();
              
          console.log('Role lookup result:', { roleData, roleError });

          if (roleError) {
            console.error('Error getting role:', roleError);
            throw new Error('Failed to find role');
          }

          if (!roleData?.id) {
            console.error('No role ID found for:', selectedRole);
            throw new Error('Role not found');
          }

          console.log('Found role ID:', roleData.id);

          // Debug: Check existing user roles
          const { data: existingRoles, error: existingError } = await supabase
            .from('user_roles')
            .select('*')
            .eq('user_id', user.id);
          console.log('Existing user roles:', existingRoles, 'Error:', existingError);

          // Delete existing roles
          console.log('Deleting existing roles...');
          const { error: deleteError } = await supabase
            .from('user_roles')
            .delete()
            .eq('user_id', user.id);

          if (deleteError) {
            console.error('Error deleting roles:', deleteError);
            throw new Error('Failed to delete existing roles');
          }

          console.log('Successfully deleted existing roles');

          // Insert new role
          console.log('Inserting new role...');
          const { data: insertData, error: insertError } = await supabase
            .from('user_roles')
            .insert({
              user_id: user.id,
              role_id: roleData.id
            })
            .select();

          if (insertError) {
            console.error('Error inserting role:', insertError);
            throw new Error('Failed to assign role');
          }

          console.log('Successfully inserted new role:', insertData);

          // Debug: Verify the role was assigned
          const { data: verifyRole, error: verifyError } = await supabase
            .from('user_roles')
            .select('*, roles!inner(*)')
            .eq('user_id', user.id)
            .single();
          console.log('Verification of role assignment:', verifyRole, 'Error:', verifyError);

          // Close dialog first
          console.log('Closing dialog...');
          onOpenChange(false);

          // Then navigate based on role
          if (selectedRole === 'teacher' && isTeacherCodeValid) {
            console.log('Navigating to teacher dashboard...');
            navigate('/teacher-dashboard', { replace: true });
          } else {
            console.log('Navigating to app...');
            navigate('/app', { replace: true });
          }
        } catch (error) {
          console.error('Error in role update:', error);
          setError(error instanceof Error ? error.message : 'Failed to update role');
          setLoading(false);
          return;
        }
      } else {
        // If no role selected, just close the dialog
        console.log('No role selected, closing dialog');
        onOpenChange(false);
      }
    } catch (err) {
      console.error('Error in handleUpdateProfile:', err);
      setError(err instanceof Error ? err.message : 'Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Profile Settings</DialogTitle>
          <DialogDescription>
            Update your profile information and role
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          <div className="grid gap-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Your name"
            />
          </div>

          <div className="grid gap-2">
            <Label>Role</Label>
            <div className="flex flex-col gap-2">
              <Button
                variant="outline"
                onClick={() => handleRoleChange('self_learner')}
                className={selectedRole === 'self_learner' ? 'bg-primary text-primary-foreground' : ''}
              >
                Self Learner
              </Button>
              <Button
                variant="outline"
                onClick={() => handleRoleChange('student')}
                className={selectedRole === 'student' ? 'bg-primary text-primary-foreground' : ''}
              >
                Student
              </Button>
              <Button
                variant="outline"
                onClick={() => handleRoleChange('teacher')}
                className={selectedRole === 'teacher' ? 'bg-primary text-primary-foreground' : ''}
              >
                Teacher
              </Button>
            </div>
          </div>

          {showTeacherCode && (
            <div className="grid gap-2">
              <Label htmlFor="teacherCode">Teacher Access Code</Label>
              <div className="flex gap-2">
                <Input
                  id="teacherCode"
                  type="text"
                  value={teacherCode}
                  onChange={(e) => setTeacherCode(e.target.value)}
                  placeholder="Enter teacher code"
                />
                <Button 
                  onClick={handleTeacherCodeSubmit}
                  disabled={loading}
                  className="whitespace-nowrap"
                >
                  Verify Code
                </Button>
              </div>
              {isTeacherCodeValid && (
                <div className="text-green-600 flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M20 6L9 17l-5-5"/>
                  </svg>
                  Code Valid
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex justify-end gap-3">
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateProfile}
            disabled={loading || (selectedRole === 'teacher' && !isTeacherCodeValid)}
          >
            Save Changes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};