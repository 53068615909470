import React, { useState } from 'react';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

export const TeacherDashboardContent = () => {
  const [selectedView, setSelectedView] = useState('overview');
  const [selectedClass, setSelectedClass] = useState('all');
  const [selectedTimeframe, setSelectedTimeframe] = useState('week');
  const [selectedLevel, setSelectedLevel] = useState('all');

  const renderClassOverview = () => (
    <div className="space-y-6">
      {/* Filters */}
      <div className="flex gap-4 flex-wrap">
        <Select value={selectedClass} onValueChange={setSelectedClass}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select Class" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Classes</SelectItem>
            <SelectItem value="a1">German 101 (A1)</SelectItem>
            <SelectItem value="a2">Intermediate (A2)</SelectItem>
            <SelectItem value="b1">Advanced (B1)</SelectItem>
          </SelectContent>
        </Select>

        <Select value={selectedTimeframe} onValueChange={setSelectedTimeframe}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Timeframe" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="week">This Week</SelectItem>
            <SelectItem value="month">This Month</SelectItem>
            <SelectItem value="semester">This Semester</SelectItem>
          </SelectContent>
        </Select>

        <Select value={selectedLevel} onValueChange={setSelectedLevel}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="CEFR Level" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Levels</SelectItem>
            <SelectItem value="a1">A1</SelectItem>
            <SelectItem value="a2">A2</SelectItem>
            <SelectItem value="b1">B1</SelectItem>
            <SelectItem value="b2">B2</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* Performance Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <Card>
          <CardContent className="p-6">
            <h3 className="text-sm font-medium text-gray-500">Class Average</h3>
            <div className="mt-2 flex items-baseline">
              <p className="text-3xl font-semibold text-gray-900">84%</p>
              <p className="ml-2 text-sm text-green-600">+2.5%</p>
            </div>
            <div className="mt-4 h-2 bg-gray-100 rounded-full overflow-hidden">
              <div className="h-full bg-green-500 rounded-full" style={{ width: '84%' }}></div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <h3 className="text-sm font-medium text-gray-500">Completion Rate</h3>
            <div className="mt-2 flex items-baseline">
              <p className="text-3xl font-semibold text-gray-900">92%</p>
              <p className="ml-2 text-sm text-green-600">+4.1%</p>
            </div>
            <div className="mt-4 h-2 bg-gray-100 rounded-full overflow-hidden">
              <div className="h-full bg-blue-500 rounded-full" style={{ width: '92%' }}></div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <h3 className="text-sm font-medium text-gray-500">Active Students</h3>
            <div className="mt-2 flex items-baseline">
              <p className="text-3xl font-semibold text-gray-900">24/25</p>
              <p className="ml-2 text-sm text-yellow-600">-1</p>
            </div>
            <div className="mt-4 h-2 bg-gray-100 rounded-full overflow-hidden">
              <div className="h-full bg-yellow-500 rounded-full" style={{ width: '96%' }}></div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <h3 className="text-sm font-medium text-gray-500">Assignments Due</h3>
            <div className="mt-2 flex items-baseline">
              <p className="text-3xl font-semibold text-gray-900">3</p>
              <p className="ml-2 text-sm text-gray-600">this week</p>
            </div>
            <div className="mt-4 flex justify-end">
              <Button variant="outline" size="sm">View All</Button>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Grammar Performance */}
      <Card>
        <CardContent className="p-6">
          <h3 className="text-lg font-semibold mb-4">Grammar Performance</h3>
          <div className="space-y-4">
            {[
              { name: 'Articles', score: 87, trend: '+3.2%' },
              { name: 'Cases', score: 82, trend: '+1.8%' },
              { name: 'Prepositions', score: 75, trend: '-2.1%' },
            ].map((item) => (
              <div key={item.name} className="flex items-center justify-between">
                <div className="flex-1">
                  <div className="flex justify-between mb-1">
                    <span className="text-sm font-medium">{item.name}</span>
                    <span className="text-sm font-medium">{item.score}%</span>
                  </div>
                  <div className="h-2 bg-gray-100 rounded-full">
                    <div 
                      className={`h-full rounded-full ${
                        item.score >= 85 ? 'bg-green-500' : 
                        item.score >= 70 ? 'bg-yellow-500' : 'bg-red-500'
                      }`}
                      style={{ width: `${item.score}%` }}
                    ></div>
                  </div>
                </div>
                <span className={`ml-4 text-sm ${
                  item.trend.startsWith('+') ? 'text-green-600' : 'text-red-600'
                }`}>
                  {item.trend}
                </span>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Common Errors */}
      <Card>
        <CardContent className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Common Errors</h3>
            <Button variant="outline" size="sm">Export Report</Button>
          </div>
          <div className="space-y-4">
            {[
              { error: 'der → dem in Dativ', count: 45, example: 'mit der Frau → mit dem Frau' },
              { error: 'die → das in Nominativ', count: 38, example: 'die Auto → das Auto' },
              { error: 'den → dem in Dativ', count: 32, example: 'mit den Mann → mit dem Mann' },
            ].map((item, index) => (
              <div key={index} className="flex items-start justify-between p-4 bg-gray-50 rounded-lg">
                <div>
                  <p className="font-medium text-gray-900">{item.error}</p>
                  <p className="text-sm text-gray-500">Example: {item.example}</p>
                </div>
                <div className="text-right">
                  <p className="font-medium text-gray-900">{item.count}</p>
                  <p className="text-sm text-gray-500">occurrences</p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Recent Activity */}
      <Card>
        <CardContent className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Recent Activity</h3>
            <Button variant="outline" size="sm">View All</Button>
          </div>
          <div className="space-y-4">
            {[
              {
                student: "Maria Schmidt",
                action: "Completed Dativ Case Assignment",
                score: "92%",
                time: "10 minutes ago"
              },
              {
                student: "John Weber",
                action: "Started Article Practice",
                score: "In Progress",
                time: "25 minutes ago"
              },
              {
                student: "Anna Müller",
                action: "Submitted Weekly Assessment",
                score: "88%",
                time: "1 hour ago"
              }
            ].map((activity, index) => (
              <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div>
                  <p className="font-medium text-gray-900">{activity.student}</p>
                  <p className="text-sm text-gray-500">{activity.action}</p>
                </div>
                <div className="text-right">
                  <p className="font-medium text-gray-900">{activity.score}</p>
                  <p className="text-sm text-gray-500">{activity.time}</p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderContent = () => {
    switch (selectedView) {
      case 'overview':
        return renderClassOverview();
      case 'students':
        return <div>Student Profiles View</div>;
      case 'content':
        return <div>Content Manager View</div>;
      case 'assignments':
        return <div>Assignment Builder View</div>;
      case 'analytics':
        return <div>Analytics View</div>;
      case 'resources':
        return <div>Resource Library View</div>;
      case 'announcements':
        return <div>Announcements View</div>;
      case 'curriculum':
        return <div>Curriculum Alignment View</div>;
      default:
        return renderClassOverview();
    }
  };

  return (
    <div>
      {/* Navigation */}
      <div className="mb-6">
        <div className="flex gap-2 overflow-x-auto pb-4">
          {[
            { id: 'overview', name: 'Overview' },
            { id: 'students', name: 'Students' },
            { id: 'content', name: 'Content' },
            { id: 'assignments', name: 'Assignments' },
            { id: 'analytics', name: 'Analytics' },
            { id: 'resources', name: 'Resources' },
            { id: 'announcements', name: 'Announcements' },
            { id: 'curriculum', name: 'Curriculum' }
          ].map((view) => (
            <Button
              key={view.id}
              variant={selectedView === view.id ? 'default' : 'outline'}
              onClick={() => setSelectedView(view.id)}
              className="whitespace-nowrap"
            >
              {view.name}
            </Button>
          ))}
        </div>
      </div>

      {/* Content */}
      {renderContent()}
    </div>
  );
};