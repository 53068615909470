import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { supabase } from "../../lib/supabase";
import { Navigation } from "./Navigation";
import { GameArea } from "./GameArea";
import { motion } from "framer-motion";
import { TeacherDashboard } from './TeacherDashboard';

interface UserRoleData {
  role_id: string;
  roles: {
    name: string;
  };
}

interface NavigationItem {
  id: number;
  name: string;
  icon: string;
  active: boolean;
}

const teacherNavigationItems: NavigationItem[] = [
  { id: 1, name: "Teacher Dashboard", icon: "/icons-2.svg", active: true },
  { id: 2, name: "Dashboard", icon: "/icons-2.svg", active: false },
  { id: 3, name: "Classes", icon: "/icons-6.svg", active: false },
  { id: 4, name: "Student Progress", icon: "/icons.svg", active: false },
  { id: 5, name: "Nominativ", icon: "/icons.svg", active: false },
  { id: 6, name: "Akkusativ", icon: "/icons.svg", active: false },
  { id: 7, name: "Dativ", icon: "/icons.svg", active: false },
  { id: 8, name: "Genativ", icon: "/icons.svg", active: false },
  { id: 9, name: "Shuffle Mode", icon: "/icons.svg", active: false },
  { id: 10, name: "Homework", icon: "/icons-6.svg", active: false },
];

const defaultNavigationItems: NavigationItem[] = [
  { id: 1, name: "Dashboard", icon: "/icons-2.svg", active: false },
  { id: 2, name: "Nominativ", icon: "/icons.svg", active: true },
  { id: 3, name: "Akkusativ", icon: "/icons.svg", active: false },
  { id: 4, name: "Dativ", icon: "/icons.svg", active: false },
  { id: 5, name: "Genativ", icon: "/icons.svg", active: false },
  { id: 6, name: "Shuffle Mode", icon: "/icons.svg", active: false },
  { id: 7, name: "Homework", icon: "/icons-6.svg", active: false },
];

export const Box = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading } = useAuth();
  const [roleCheckComplete, setRoleCheckComplete] = useState(false);
  const [activeNav, setActiveNav] = useState(location.state?.activeNav || 2);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isTeacher, setIsTeacher] = useState(false);
  
  // Redirect to signin if not authenticated and not in the process of setting up
  useEffect(() => {
    const isAuthPath = ['/signin', '/signup', '/select-role', '/auth/callback'].includes(location.pathname);
    if (!loading && !user && !isAuthPath) {
      console.log('Box: No authenticated user found, redirecting to signin');
      navigate('/signin', { replace: true });
    }
  }, [user, loading, navigate, location.pathname]);

  // Check if the user has a role
  useEffect(() => {
    const checkUserRole = async () => {
      if (!user || loading || location.pathname === '/select-role') {
        return;
      }
      
      try {
        console.log('Box: Checking if user has a role');
        // Get user's role with a join
        const { data: userRoleData, error: userRoleError } = await supabase
          .from('user_roles')
          .select(`
            role_id,
            roles (
              name
            )
          `)
          .eq('user_id', user.id)
          .maybeSingle() as { data: UserRoleData | null, error: any };
        
        console.log('User role query result:', { userRoleData, userRoleError });
        
        if (userRoleError) {
          console.error('Error checking user_roles:', userRoleError);
          return;
        }

        if (!userRoleData && !location.pathname.startsWith('/auth')) {
          // No role found, redirect to role selection
          console.log('No role found, redirecting to role selection');
          navigate('/select-role', { replace: true });
          return;
        }

        // Role found, user can stay in the Box component
        if (userRoleData) {
          console.log('User has role:', userRoleData.roles.name);
          setIsTeacher(userRoleData.roles.name === 'teacher');
          setRoleCheckComplete(true);
        }
      } catch (error) {
        console.error('Error checking role:', error);
      }
    };
    
    checkUserRole();
  }, [user, loading, navigate, location.pathname]);

  // Set active nav based on URL mode parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode');
    if (mode) {
      switch (mode) {
        case 'nominativ': setActiveNav(isTeacher ? 5 : 2); break;
        case 'akkusativ': setActiveNav(isTeacher ? 6 : 3); break;
        case 'dativ': setActiveNav(isTeacher ? 7 : 4); break;
        case 'genativ': setActiveNav(isTeacher ? 8 : 5); break;
        case 'shuffle': setActiveNav(isTeacher ? 9 : 6); break;
        case 'homework': setActiveNav(isTeacher ? 10 : 7); break;
      }
    }
  }, [location.search, isTeacher]);

  return (
    <div className="flex min-h-screen w-full bg-white">
      <Navigation 
        user={user}
        activeNav={activeNav}
        setActiveNav={setActiveNav}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigationItems={isTeacher ? teacherNavigationItems : defaultNavigationItems}
      />

      <main className={`flex-1 p-8 ${sidebarOpen ? 'ml-[300px]' : 'ml-0'}`}>
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="mb-8 rounded-full bg-white p-2 shadow-md hover:bg-gray-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`transition-transform duration-300 ${sidebarOpen ? 'rotate-180' : ''}`}
          >
            <path d="m9 18 6-6-6-6"/>
          </svg>
        </button>

        <GameArea 
          activeNav={activeNav}
          roleCheckComplete={roleCheckComplete}
        />
      </main>
    </div>
  );
};