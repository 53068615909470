import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { supabase } from "../../lib/supabase";
import { Navigation } from "./Navigation";
import { TeacherDashboardContent } from "../../components/TeacherDashboardContent";

// Import the NavigationItem interface from Navigation
interface NavigationItem {
  id: number;
  name: string;
  icon: string;
  active: boolean;
}

const teacherNavigationItems: NavigationItem[] = [
  // Teacher-specific items
  { id: 1, name: "Teacher Dashboard", icon: "/icons-2.svg", active: true },
  { id: 2, name: "Classes", icon: "/icons-6.svg", active: false },
  { id: 3, name: "Student Progress", icon: "/icons.svg", active: false },
  { id: 4, name: "Assignments", icon: "/icons.svg", active: false },
  // Regular navigation items
  { id: 5, name: "Nominativ", icon: "/icons.svg", active: false },
  { id: 6, name: "Akkusativ", icon: "/icons.svg", active: false },
  { id: 7, name: "Dativ", icon: "/icons.svg", active: false },
  { id: 8, name: "Genativ", icon: "/icons.svg", active: false },
  { id: 9, name: "Shuffle Mode", icon: "/icons.svg", active: false },
  { id: 10, name: "Homework", icon: "/icons-6.svg", active: false },
];

export const TeacherDashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeNav, setActiveNav] = useState(1);  // Default to Teacher Dashboard

  // Verify teacher access
  useEffect(() => {
    const checkTeacherAccess = async () => {
      if (!user) {
        navigate('/signin', { replace: true });
        return;
      }

      try {
        const { data: userRole, error } = await supabase
          .from('user_roles')
          .select(`
            role_id,
            roles (
              name
            )
          `)
          .eq('user_id', user.id)
          .maybeSingle();

        if (error || userRole?.roles?.name !== 'teacher') {
          console.error('Not authorized as teacher:', error);
          navigate('/app', { replace: true });
          return;
        }
      } catch (err) {
        console.error('Error checking teacher access:', err);
        navigate('/app', { replace: true });
      }
    };

    checkTeacherAccess();
  }, [user, navigate]);

  const handleNavigation = (section: string) => {
    switch (section) {
      case 'nominativ':
        navigate('/app?mode=nominativ', { state: { fromTeacherDashboard: true } });
        break;
      case 'akkusativ':
        navigate('/app?mode=akkusativ', { state: { fromTeacherDashboard: true } });
        break;
      case 'dativ':
        navigate('/app?mode=dativ', { state: { fromTeacherDashboard: true } });
        break;
      case 'genativ':
        navigate('/app?mode=genativ', { state: { fromTeacherDashboard: true } });
        break;
      case 'shuffle':
        navigate('/app?mode=shuffle', { state: { fromTeacherDashboard: true } });
        break;
      case 'homework':
        navigate('/app?mode=homework', { state: { fromTeacherDashboard: true } });
        break;
    }
  };

  if (!user) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="text-center">
          <h2 className="mb-4 text-2xl font-semibold">Loading...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen w-full bg-white">
      <Navigation 
        user={user}
        activeNav={activeNav}
        setActiveNav={(id) => {
          setActiveNav(id);
          // Map navigation IDs to sections
          switch (id) {
            case 5: handleNavigation('nominativ'); break;
            case 6: handleNavigation('akkusativ'); break;
            case 7: handleNavigation('dativ'); break;
            case 8: handleNavigation('genativ'); break;
            case 9: handleNavigation('shuffle'); break;
            case 10: handleNavigation('homework'); break;
          }
        }}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigationItems={teacherNavigationItems}
      />

      <main className={`flex-1 p-8 ${sidebarOpen ? 'ml-[300px]' : 'ml-0'}`}>
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="mb-8 rounded-full bg-white p-2 shadow-md hover:bg-gray-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`transition-transform duration-300 ${sidebarOpen ? 'rotate-180' : ''}`}
          >
            <path d="m9 18 6-6-6-6"/>
          </svg>
        </button>

        {/* Dashboard Content */}
        <TeacherDashboardContent />
      </main>
    </div>
  );
};