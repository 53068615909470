import React, { createContext, useContext, useEffect, useState } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';

interface UserRole {
  roles: {
    name: string;
  };
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signOut: () => Promise<void>;
  signInWithGoogle: () => Promise<any>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const checkUserRoleAndNavigate = async (currentUser: User) => {
    console.log('[Auth] Checking user role and navigating', { userId: currentUser.id });

    try {
      // First check if user exists in profiles table
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', currentUser.id)
        .single();

      console.log('[Auth] Profile check result:', { profile, profileError });

      if (profileError) {
        if (profileError.code === 'PGRST116') {
          // User doesn't exist in profiles table, they are a new user
          console.log('[Auth] New user detected, redirecting to role selection');
          navigate('/select-role', { replace: true });
          return;
        }
        throw profileError;
      }

      // Then check user role
      const { data: userRole, error: roleError } = await supabase
        .from('user_roles')
        .select('roles(name)')
        .eq('user_id', currentUser.id)
        .maybeSingle() as { data: UserRole | null, error: any };

      console.log('[Auth] Role check result:', { userRole, roleError });

      if (roleError && roleError.code !== 'PGRST116') {
        console.error('[Auth] Role check error:', roleError);
        throw roleError;
      }

      if (!userRole) {
        console.log('[Auth] No role found, redirecting to role selection');
        navigate('/select-role', { replace: true });
        return;
      }

      if (userRole?.roles?.name === 'teacher') {
        console.log('[Auth] Teacher role found, redirecting to dashboard');
        navigate('/teacher-dashboard', { replace: true });
      } else {
        console.log('[Auth] Student/self-learner role found, redirecting to app');
        navigate('/app', { replace: true });
      }
    } catch (error) {
      console.error('[Auth] Error checking role:', error);
      // If there's an error checking the role, redirect to role selection
      navigate('/select-role', { replace: true });
    }
  };

  useEffect(() => {
    const timestamp = new Date().toISOString();
    console.log(`[Auth ${timestamp}] Initializing auth provider`);

    let isInitialSessionCheck = true;

    // Get initial session
    supabase.auth.getSession().then(({ data: { session }, error }) => {
      console.log('[Auth] Initial session check:', { session: !!session, error });
      
      if (session?.user) {
        console.log('[Auth] Found existing session for user:', session.user.id);
        setUser(session.user);
        if (isInitialSessionCheck) {
          checkUserRoleAndNavigate(session.user);
        }
      }
      
      setLoading(false);
      isInitialSessionCheck = false;
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log(`[Auth ${new Date().toISOString()}] Auth state change`, { event, userId: session?.user?.id });
      
      if (session?.user) {
        setUser(session.user);
        // Only navigate on explicit sign in or token refresh, not on session recovery
        if ((event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') && !isInitialSessionCheck) {
          console.log('[Auth] Processing auth event:', event, { userId: session.user.id });
          await checkUserRoleAndNavigate(session.user);
        } else {
          console.log('[Auth] Skipping navigation for event:', event);
        }
      } else {
        console.log('[Auth] No user in session, clearing state');
        setUser(null);
        // Only navigate on explicit sign out, not on session expiry
        if (event === 'SIGNED_OUT') {
          navigate('/signin', { replace: true });
        }
      }
    });

    return () => {
      console.log('[Auth] Cleaning up auth subscriptions');
      subscription.unsubscribe();
    };
  }, [navigate]);

  const signIn = async (email: string, password: string) => {
    console.log('[Auth] Attempting sign in for:', email);
    
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    console.log('[Auth] Sign in result:', { success: !!data.session, error });

    if (error) {
      console.error('[Auth] Sign in error:', error);
      throw error;
    }

    // The onAuthStateChange listener will handle navigation after successful sign-in
  };

  const signUp = async (email: string, password: string, name: string) => {
    console.log('[Auth] Starting sign up for:', email);
    
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: { full_name: name }
      }
    });

    console.log('[Auth] Sign up result:', { success: !!data.user, error });

    if (error) {
      console.error('[Auth] Sign up error:', error);
      throw error;
    }

    // After successful signup, redirect to role selection
    if (data.user) {
      console.log('[Auth] Sign up successful, redirecting to role selection');
      navigate('/select-role', { replace: true });
    }
  };

  const signInWithGoogle = async () => {
    console.log('[Auth] Starting Google sign in');
    
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}`,
        queryParams: {
          access_type: 'offline',
          prompt: 'consent'
        }
      }
    });

    console.log('[Auth] Google sign in result:', { url: !!data.url, error });

    if (error) {
      console.error('[Auth] Google sign in error:', error);
      throw error;
    }

    if (data.url) {
      console.log('[Auth] Redirecting to Google OAuth URL');
      window.location.href = data.url;
    }
  };

  const signOut = async () => {
    console.log('[Auth] Starting sign out');
    
    const { error } = await supabase.auth.signOut();
    
    if (error) {
      console.error('[Auth] Sign out error:', error);
      throw error;
    }

    console.log('[Auth] Sign out successful');
    navigate('/signin', { replace: true });
  };

  return (
    <AuthContext.Provider value={{
      user,
      loading,
      signIn,
      signUp,
      signOut,
      signInWithGoogle
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};