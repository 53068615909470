import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { Box } from "./screens/Box";
import { SignIn } from "./screens/Auth/SignIn";
import { SignUp } from "./screens/Auth/SignUp";
import { PostAuthRole } from "./components/PostAuthRole";
import { Landing } from "./screens/Landing/Landing";
import { TeacherDashboard } from "./screens/Box/TeacherDashboard";
import "./styles.css";

// Root layout component that includes AuthProvider
const RootLayout = () => {
  return (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/app",
        element: <Box />,
      },
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/select-role",
        element: <PostAuthRole />,
      },
      {
        path: "/teacher-dashboard",
        element: <TeacherDashboard />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />
      }
    ]
  }
]);

createRoot(document.getElementById("app") as HTMLElement).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);