// Word interface for German vocabulary
export interface GermanWord {
  english: string;
  german: string;
  article: string;
}

export interface AkkusativSentence {
  sentence: string;
  article: string;
  noun: string;
  englishMeaning: string;
  firstPart: string;
  lastPart: string;
}

// Function to parse CSV content
async function loadWordsFromCSV(): Promise<GermanWord[]> {
  try {
    const response = await fetch('/data/german-words.csv');
    const csvContent = await response.text();
    
    return csvContent
      .trim()
      .split('\n')
      .map(line => {
        const [article, german, english] = line.split(',').map(s => s.trim().replace(/^"|"$/g, ''));
        return { article, german, english };
      });
  } catch (error) {
    console.error('Error loading CSV:', error);
    return [];
  }
}

// Initialize with empty arrays
export const nominativWords: GermanWord[] = [];
export const akkusativWords: GermanWord[] = [];
export const dativWords: GermanWord[] = [];
export const genitivWords: GermanWord[] = [];

// Akkusativ sentences
export const akkusativSentences: AkkusativSentence[] = [
  {
    sentence: "Ich sehe die Wärme.",
    article: "die",
    noun: "Wärme",
    englishMeaning: "warmth, heat",
    firstPart: "Ich sehe ",
    lastPart: " Wärme."
  },
  {
    sentence: "Ich werde das Kapitel bald besuchen.",
    article: "das",
    noun: "Kapitel",
    englishMeaning: "chapter",
    firstPart: "Ich werde ",
    lastPart: " Kapitel bald besuchen."
  },
  {
    sentence: "Er fotografiert den Soldat gerne.",
    article: "den",
    noun: "Soldat",
    englishMeaning: "soldier",
    firstPart: "Er fotografiert ",
    lastPart: " Soldat gerne."
  },
  {
    sentence: "Er sucht die Einführung schon seit Stunden.",
    article: "die",
    noun: "Einführung",
    englishMeaning: "introduction",
    firstPart: "Er sucht ",
    lastPart: " Einführung schon seit Stunden."
  },
  {
    sentence: "Er hat den Zuschauer gekauft.",
    article: "den",
    noun: "Zuschauer",
    englishMeaning: "spectator",
    firstPart: "Er hat ",
    lastPart: " Zuschauer gekauft."
  },
  {
    sentence: "Sie mag das Metall nicht.",
    article: "das",
    noun: "Metall",
    englishMeaning: "metal",
    firstPart: "Sie mag ",
    lastPart: " Metall nicht."
  }
];

// Load words from CSV
loadWordsFromCSV().then(words => {
  // Fill nominativWords with all words
  nominativWords.push(...words);
  
  // Create subsets for other cases with appropriate article changes
  akkusativWords.push(...words.slice(0, 5).map(word => ({
    ...word,
    article: word.article === 'der' ? 'den' : word.article
  })));

  dativWords.push(...words.slice(5, 10).map(word => ({
    ...word,
    article: word.article === 'der' ? 'dem' : word.article === 'das' ? 'dem' : 'der'
  })));

  genitivWords.push(...words.slice(10, 15).map(word => ({
    ...word,
    article: word.article === 'der' ? 'des' : word.article === 'das' ? 'des' : 'der'
  })));
});