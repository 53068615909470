import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleSelection, UserRole } from './RoleSelection';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { Box } from '../screens/Box';

interface RoleData {
  id: string;
  name: string;
}

interface UserRoleWithName {
  role_id: string;
  roles: {
    name: 'self_learner' | 'teacher' | 'student';
  };
}

interface UserRoleData {
  role_id: string;
  role: {
    name: string;
  };
}

export const PostAuthRole = ({ forceRole }: { forceRole?: UserRole }) => {
  const [localLoading, setLocalLoading] = useState(true);
  const [error, setError] = useState('');
  const [teacherCodeVerified, setTeacherCodeVerified] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('PostAuthRole component mounted, forceRole =', forceRole);
    console.log('Initial state - user:', !!user, 'loading:', !!user);
    
    if (forceRole && user) {
      console.log('forceRole = true and user exists, showing role selection immediately');
      setLocalLoading(false);
      return;
    }
    
    const authCheckTimeout = setTimeout(() => {
      console.log('Auth check timeout completed');
    }, 2000);

    return () => {
      console.log('PostAuthRole component unmounted');
      clearTimeout(authCheckTimeout);
    };
  }, [forceRole, user]);

  useEffect(() => {
    if (forceRole && user) {
      console.log('Skipping role check due to forceRole = true');
      setLocalLoading(false);
      return;
    }
    
    const checkExistingRole = async () => {
      try {
        if (!!user) {
          console.log('Ready to check user role - user exists:', !!user);

          if (!user) {
            console.log('No user found in PostAuthRole after waiting, redirecting to signin');
            navigate('/signin', { replace: true });
            return;
          }

          console.log('User is authenticated in PostAuthRole, checking for existing role');

          try {
            // Get user's role
            const { data: userRoleData, error: userRoleError } = await supabase
              .from('user_roles')
              .select(`
                role_id,
                roles (
                  name
                )
              `)
              .eq('user_id', user.id)
              .maybeSingle();
            
            console.log('User role query result:', { userRoleData, userRoleError });

            if (userRoleError) {
              console.error('Error checking user_roles:', userRoleError);
              setError('Failed to check user role');
              setLocalLoading(false);
              return;
            }

            if (!userRoleData) {
              console.log('New user detected, showing role selection screen');
              setLocalLoading(false);
              return;
            }

            // Role found, redirect based on role
            if (userRoleData.roles.name === 'teacher') {
              console.log('Teacher role found, redirecting to dashboard');
              navigate('/teacher-dashboard', { replace: true });
            } else {
              console.log('Non-teacher role found, redirecting to app');
              navigate('/app', { replace: true });
            }
          } catch (error) {
            console.error('Error checking role:', error);
            setError('Failed to check user role');
            setLocalLoading(false);
          }
        }
      } catch (error) {
        console.error('Error in PostAuthRole:', error);
        setError('Failed to check user role');
        setLocalLoading(false);
      }
    };

    checkExistingRole();
  }, [user, navigate, forceRole]);

  const handleRoleSelect = async (role: UserRole) => {
    console.log('Role selected:', role);
    setLocalLoading(true);
    setError('');

    try {
      if (!user) {
        console.error('No user found in handleRoleSelect');
        throw new Error('No user found');
      }

      // First ensure profile exists
      console.log('Checking if profile exists');
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError && profileError.code === 'PGRST116') {
        // Profile doesn't exist, create it
        console.log('Creating profile for user');
        const { error: createError } = await supabase
          .from('profiles')
          .insert({
            id: user.id,
            full_name: user.user_metadata.full_name || user.user_metadata.name,
            avatar_url: user.user_metadata.avatar_url,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          });

        if (createError) {
          console.error('Error creating profile:', createError);
          throw new Error('Failed to create profile');
        }
      } else if (profileError) {
        console.error('Error checking profile:', profileError);
        throw new Error('Failed to check profile');
      }

      // Now check for existing role
      console.log('Checking for existing role');
      const { data: existingRole, error: checkError } = await supabase
        .from('user_roles')
        .select('role_id, roles!inner(name)')
        .eq('user_id', user.id)
        .maybeSingle();

      console.log('Existing role check:', { existingRole, checkError });

      if (checkError && checkError.code !== 'PGRST116') {
        console.error('Error checking existing role:', checkError);
        throw new Error('Failed to check existing role');
      }

      // Get role ID for the new role
      console.log('Getting role ID for:', role);
      const { data: roleData, error: roleError } = await supabase
        .from('roles')
        .select('id, name')
        .eq('name', role)
        .single();

      console.log('Role lookup result:', { roleData, roleError });

      if (roleError || !roleData) {
        console.error('Role lookup error:', roleError);
        throw new Error('Role not found');
      }

      // Delete existing role if any
      if (existingRole) {
        console.log('Removing existing role');
        const { error: deleteError } = await supabase
          .from('user_roles')
          .delete()
          .eq('user_id', user.id);

        console.log('Delete result:', { deleteError });

        if (deleteError) {
          console.error('Error deleting existing role:', deleteError);
          throw new Error('Failed to delete existing role');
        }
      }

      // Insert new role
      console.log('Assigning new role to user:', { roleId: roleData.id, userId: user.id });
      const { data: insertData, error: insertError } = await supabase
        .from('user_roles')
        .insert({
          user_id: user.id,
          role_id: roleData.id
        })
        .select();

      console.log('Insert result:', { insertData, insertError });

      if (insertError) {
        console.error('Error assigning role:', insertError);
        throw new Error('Failed to assign role');
      }

      console.log('Role assigned successfully');

      // Navigate based on role
      if (role === 'teacher' && teacherCodeVerified) {
        console.log('Navigating to teacher dashboard');
        navigate('/teacher-dashboard', { replace: true });
      } else {
        console.log('Navigating to app');
        navigate('/app', { replace: true });
      }
    } catch (error) {
      console.error('Role assignment error:', error);
      setError('Failed to assign role. Please try again.');
    } finally {
      setLocalLoading(false);
    }
  };

  if (localLoading) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-xl">
          <div className="text-center">
            <h2 className="text-2xl font-semibold mb-2">Setting up your account</h2>
            <p className="text-gray-600">Please wait while we get everything ready...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Blurred Dashboard Background */}
      <div className="fixed inset-0 filter blur-sm">
        <Box />
      </div>

      {/* Role Selection Overlay */}
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="bg-white p-8 rounded-xl shadow-xl max-w-2xl w-full mx-4"
        >
          <div className="text-center mb-8">
            <img
              src="/deutschlylogo-1.png"
              alt="Deutschly Logo"
              className="h-12 mx-auto mb-6"
            />
            <h2 className="text-3xl font-bold mb-3">Welcome to Deutschly!</h2>
            <p className="text-gray-600 text-lg">
              To personalize your experience, please select your role
            </p>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 text-red-600 rounded-lg">
              {error}
            </div>
          )}

          <RoleSelection onSelect={handleRoleSelect} />
        </motion.div>
      </div>
    </>
  );
};