import React, { useState, useEffect } from "react";
import { Dashboard } from "./components/Dashboard";
import { Settings } from "./components/Settings";
import { Game } from "./components/Game";
import { navigationItems } from "./constants";
import { useAuth } from "../../contexts/AuthContext";
import { supabase } from "../../lib/supabase";
import { TeacherDashboardContent } from "../../components/TeacherDashboardContent";

interface GameAreaProps {
  activeNav: number;
  roleCheckComplete: boolean;
}

export const GameArea: React.FC<GameAreaProps> = ({ activeNav, roleCheckComplete }) => {
  const [showTable, setShowTable] = useState(false);
  const [selectedFont, setSelectedFont] = useState('times');
  const [autoCheck, setAutoCheck] = useState(true);
  const [isTeacher, setIsTeacher] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const checkTeacherRole = async () => {
      if (!user) return;

      try {
        const { data: userRole } = await supabase
          .from('user_roles')
          .select('roles(name)')
          .eq('user_id', user.id)
          .single();

        setIsTeacher(userRole?.roles?.name === 'teacher');
      } catch (error) {
        console.error('Error checking teacher role:', error);
      }
    };

    checkTeacherRole();
  }, [user]);

  // If we're in a game mode (activeNav > 4 for regular users, > 5 for teachers), render the Game component
  if ((isTeacher && activeNav > 5) || (!isTeacher && activeNav > 4)) {
    return (
      <Game
        activeNav={activeNav}
        showTable={showTable}
        selectedFont={selectedFont}
        autoCheck={autoCheck}
      />
    );
  }

  // For the teacher dashboard view (activeNav === 1 for teachers)
  if (isTeacher && activeNav === 1) {
    return <TeacherDashboardContent />;
  }

  // For the regular dashboard view (activeNav === 1 for regular users, activeNav === 2 for teachers)
  if (activeNav === 1 || (isTeacher && activeNav === 2)) {
    return (
      <div className="relative">
        <div className="sticky top-0 z-40 bg-white pb-8">
          <header className="flex items-center justify-between">
            <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
          </header>
        </div>
        <div className="relative">
          <Dashboard setActiveNav={(id) => activeNav = id} />
        </div>
      </div>
    );
  }

  // For other views (2-4 for regular users, 3-5 for teachers), render the Game component with the appropriate mode
  return (
    <div className="relative">
      <div className="sticky top-0 z-40 bg-white pb-8">
        <header className="flex items-center justify-between">
          <h1 className="text-3xl font-bold text-gray-900">
            {navigationItems.find(item => item.id === activeNav)?.name}
          </h1>
          <Settings
            selectedFont={selectedFont}
            setSelectedFont={setSelectedFont}
            autoCheck={autoCheck}
            setAutoCheck={setAutoCheck}
            showTable={showTable}
            setShowTable={setShowTable}
          />
        </header>
      </div>
      <div className="relative">
        <Game
          activeNav={activeNav}
          showTable={showTable}
          selectedFont={selectedFont}
          autoCheck={autoCheck}
        />
      </div>
    </div>
  );
};