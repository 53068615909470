import { Button } from "../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { motion } from "framer-motion";
import { fontOptions } from "../constants";

interface SettingsProps {
  selectedFont: string;
  setSelectedFont: (font: string) => void;
  autoCheck: boolean;
  setAutoCheck: (check: boolean) => void;
  showTable: boolean;
  setShowTable: (show: boolean) => void;
}

const buttonTextVariants = {
  initial: { rotateX: 0 },
  flipped: { rotateX: 180 }
};

const buttonContainerVariants = {
  initial: { rotateX: 0 },
  flipped: { rotateX: 180 }
};

export const Settings: React.FC<SettingsProps> = ({
  selectedFont,
  setSelectedFont,
  autoCheck,
  setAutoCheck,
  showTable,
  setShowTable,
}) => {
  return (
    <div className="flex gap-3">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="h-8 sm:h-10 rounded-full border-[#b3b3b3] bg-neutral-50 px-4 sm:px-6 text-xs sm:text-sm font-medium text-black shadow-sm transition-all hover:bg-neutral-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2"
            >
              <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"/>
              <circle cx="12" cy="12" r="3"/>
            </svg>
            Settings
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-64">
          <div className="space-y-4">
            <div className="space-y-2">
              <h4 className="font-medium leading-none">Font</h4>
              <p className="text-sm text-muted-foreground">
                Select the font for German words
              </p>
              <Select value={selectedFont} onValueChange={setSelectedFont}>
                <SelectTrigger>
                  <SelectValue placeholder="Select font" />
                </SelectTrigger>
                <SelectContent>
                  {fontOptions.map((font) => (
                    <SelectItem
                      key={font.value}
                      value={font.value}
                      className={font.className}
                    >
                      {font.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <h4 className="font-medium leading-none">Auto-Check</h4>
              <p className="text-sm text-muted-foreground">
                Enable automatic checking as you type
              </p>
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="autoCheck"
                  checked={autoCheck}
                  onChange={(e) => setAutoCheck(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300"
                />
                <label htmlFor="autoCheck" className="text-sm">
                  Check answers automatically
                </label>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
      <div className="relative">
        <motion.div
          initial="initial"
          animate={showTable ? "flipped" : "initial"}
          variants={buttonContainerVariants}
          transition={{ duration: 0.3 }}
          style={{ transformStyle: "preserve-3d" }}
        >
          <Button
            variant="outline"
            className="h-10 rounded-full border-[#b3b3b3] bg-neutral-50 px-6 text-sm font-medium text-black shadow-sm transition-all hover:bg-neutral-100"
            onClick={() => setShowTable(!showTable)}
          >
            <motion.span
              variants={buttonTextVariants}
              transition={{ duration: 0.3 }}
              style={{ display: "block" }}
            >
              {showTable ? "Hide Table" : "Show Table"}
            </motion.span>
          </Button>
        </motion.div>
      </div>
      <Button 
        className="h-10 rounded-full bg-[#444444] px-6 text-sm font-medium text-white shadow-sm transition-all hover:bg-[#333333]"
      >
        View Progress
      </Button>
    </div>
  );
};