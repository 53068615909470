import { nominativWords, akkusativWords, dativWords, genitivWords, akkusativSentences } from "../../data/words";

export const allWords = [...nominativWords, ...akkusativWords, ...dativWords, ...genitivWords];

export const navigationItems = [
  { id: 1, name: "Dashboard", icon: "/icons-2.svg", active: false, words: [], color: "bg-blue-500/10 text-blue-600" },
  { id: 2, name: "Nominativ", icon: "/icons.svg", active: true, words: nominativWords, color: "bg-green-500/10 text-green-600" },
  { id: 3, name: "Akkusativ", icon: "/icons.svg", active: false, words: akkusativSentences, color: "bg-purple-500/10 text-purple-600" },
  { id: 4, name: "Dativ", icon: "/icons.svg", active: false, words: dativWords, color: "bg-orange-500/10 text-orange-600" },
  { id: 5, name: "Genativ", icon: "/icons.svg", active: false, words: genitivWords, color: "bg-red-500/10 text-red-600" },
  { id: 6, name: "Shuffle Mode", icon: "/icons.svg", active: false, words: allWords, color: "bg-indigo-500/10 text-indigo-600" },
  { id: 7, name: "Homework", icon: "/icons-6.svg", active: false, words: [], color: "bg-yellow-500/10 text-yellow-600" },
];

export const fontOptions = [
  { value: 'times', label: 'Times New Roman', className: 'font-["Times_New_Roman"]' },
  { value: 'satoshi', label: 'Satoshi', className: 'font-["Satoshi"]' },
  { value: 'merriweather', label: 'Merriweather', className: 'font-["Merriweather"]' },
  { value: 'crimson', label: 'Crimson Text', className: 'font-["Crimson_Text"]' },
];

export const defaultWord = {
  english: "Loading...",
  german: "Laden...",
  article: "..."
};

export function shuffleArray<T>(array: T[]): T[] {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}